import { VariantProps, cva } from "class-variance-authority";
import { cn } from "utils/utils";

const mainPageWrapperVariants = cva(
  "relative min-h-screen flex flex-col w-full max-w-full",
  {
    variants: {
      color: {
        midnightblue: "bg-midnightblue",
        teal: "bg-teal",
        turquoise: "bg-turquoise",
        white: "bg-white",
        "light-iron": "bg-light-iron",
        iron: "bg-iron",
        "dark-iron": "bg-dark-iron",
        "gray-100": "bg-gray-100",
        gray: "bg-gray",
      },
      marginTop: {
        default: "",
        // default: "pt-8 md:pt-10 lg:pt-14",
        desktopOnly: "pt-0 md:pt-10 lg:pt-14",
        none: "",
      },
    },
    defaultVariants: {
      color: "midnightblue",
      marginTop: "default",
    },
  },
);

export const MainPageWrapper = ({
  className,
  children,
  color,
  marginTop,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
  VariantProps<typeof mainPageWrapperVariants>) => {
  return (
    <main
      {...props}
      className={cn(mainPageWrapperVariants({ color, marginTop }), className)}
    >
      {children}
    </main>
  );
};
